<template>
    <section class="content">
        <div class="card card-primary">
                <div class="card-body">
                    <div class="text-right mb-3">
    <router-link class="btn btn-primary" to="/orderform"><i class="fa fa-plus"></i> Tulis Kiriman</router-link>                        
                        <button class="btn btn-secondary ml-3" @click.prevent="downloadTemplate"><i class="fa fa-download"></i> Unduh Template <span v-if="loadingDownload" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                    </div>
                    <form action="/target" ref="dzone" class="dropzone card" style="border-style: dashed;">
                        <div class="overlay" v-if="loading">
                <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
              </div>
              <div class="dz-message text-muted" data-dz-message><h4><div><i class="fa fa-upload"></i></div><span>Upload file Anda disini</span></h4></div>
                    </form>

                </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import Dropzone from "dropzone";

import Swal from 'sweetalert2';
import $ from 'jquery';
import "dropzone/dist/dropzone.css";

export default {
    name: 'Uploader',
    data() {
        return {
            roles: '',
            loading: false,
            loadingDownload: false,
            data: [],
            formTitle: 'Tambah User',
            form: {
                query: '',
            }
        }
    },
    computed: {},
    created: function() {

    },
    methods: {
        downloadTemplate: function()
        {
            this.loadingDownload = true;
            authFetch("/file/aset/template", {
                method: 'GET',
            })
            .then(response => response.blob())
            .then(blob => {
                this.loadingDownload = false;
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "template1.xlsx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again         
            });

        }
    },
    mounted() {
        Dropzone.autoDiscover = false;

        let myDropzone = new Dropzone(this.$refs.dzone, {
            uploadMultiple: false, maxFiles: 1,
            createImageThumbnails: false,autoProcessQueue: false,
            url: 'getMeSomeUrl',
            accept: function(file, done) {
                console.log('accept', file, done)
                this.removeFile(file);
            },
            success: function(file)
            {
                this.removeFile(file);
            }
            //previewTemplate : '<div style="display:none"></div>'
        });
        myDropzone.on("addedfile", file => {
          console.log(`File added: ${file.name}`);
          console.log(file)
            this.loading = true;
                var formData = new FormData();
                formData.append('file', file);
                formData.append('id',123);
                console.log('formdata', formData)
                authFetch('/order/import', {
                  method: 'POST',
                  body: formData,
                  headers: {
                    'Content-Type': null,
                  }
                }).then(res => res.json())
    .then(json => {
            this.loading = false;
        if(json.success)
        {
            this.$router.push({ path: '/orderform' })
        }
        else{
            Swal.fire(json.data.msg, '', 'error')
        }
    })
    .catch(err => console.error(err));
          
        });
        myDropzone.on("maxfilesexceeded", function (file) {
            myDropzone.removeAllFiles();
            //myDropzone.addFile(file);
        });
    }
}
</script>